.footer {
    margin-top: 120px;
}

.border {
    border-top: 1px solid #CAABC7;
    padding-top: 24px;
}

.social {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 0;
}

.social a img {
    width: 42px;
    height: 42px;
}

.social a:not(:first-child) {
    margin-left: -8px;
}

.menu {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 8px;
}

.menu a {
    color: var(--color-txtColor);
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 32px;
    transition: .3s;
}

.menu a:hover {
    color: var(--color-primary);
}

.creditos {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px 0;
}

.link {
    color: var(--color-primary);
    cursor: pointer;
    transition: .3s;
}

.link:hover {
    color: var(--color-primary2);
}

.logo {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    height: 90%;
}


.header {
    position: relative;
    z-index: 2;
}

.flex {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 90px;
}

.menu {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 28px;
}

.menu a {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    line-height: 32px;
    color: #FFFFFF;
    transition: .3s;
}

.menu a:hover {
    color: var(--color-primary);
}

.toggleMenu {
    display: none;
}

@media(max-width: 991px) {
    .menu {
        position: fixed;
        top: 0;
        right: -300px;
        width: 300px;
        height: 100vh;
        background: var(--color-secondary2);
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        padding: 16px;
        transition: .3s;
        z-index: 999998;
    }

    .menuOpen {
        right: 0;
    }

    .toggleMenu {
        position: relative;
        z-index: 999999;
        display: block
    }

    .menu a,
    .menu div {
        width: 100%;
    }
}

.submenu {
    position: relative;
}

.submenu p {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    line-height: 32px;
    color: #FFFFFF;
    transition: .3s;
}

.submenu:hover p {
    color: var(--color-primary);
}

.submenu:hover .submenu__items {
    opacity: 1;
    pointer-events: all;
}

.submenu__items {
    background: #FFFFFF;
    box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 30px;
    left: 0;
    width: fit-content;
    overflow: hidden;
    opacity: 0;
    pointer-events: none;
    z-index: 9;
}

.submenu__items a {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    color: #000000;
    padding: 10px 16px;
    white-space: nowrap;
    transition: .3s;
}

.submenu__items a:hover {
    background: #5DABC4;
    color: #000000;
}

.social {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0;
}

.social img {
    margin: 0 auto;
    display: block;
    width: 36px;
    height: 36px;
    filter: brightness(10000);
}